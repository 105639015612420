export default {
  install(Vue, options) {
    // create a mixin 
    Vue.mixin({
      created() {
      }
    });

    Vue.VERSION = 'v2.0.3';

    Vue.prototype.$thousandSeparator = function (amount) {
      if (amount !== '' &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== '0' &&
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return 0
      }

    }
  }
}