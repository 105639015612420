import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import router from './router'
import store from './store'
import App from './App.vue'
import hasFonction from './libs/hasFonction'
import thousandSeparator from './libs/thousandSeparator'
import formatDate from './libs/formatDate'
import thousandFormater from './libs/thousandFormater'
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

import auth from './libs/auth'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(hasFonction)
Vue.use(thousandSeparator)
Vue.use(formatDate)
Vue.use(thousandFormater)

import { localize, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";

localize({
  en,
  fr
});
localize("fr");
extend("required", required);


Vue.component('v-select', vSelect)
Vue.component("ValidationProvider", ValidationProvider);

//Vue.use(auth)

// Composition API

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

var bus = new Vue({});
