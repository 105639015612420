import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters'
import mutations from './mutations'
import actions from './actions'

// Modules
import user from './user'
import devises from './devises'
import societes from './societes'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import subscription from './subscription'

Vue.use(Vuex)
const initialState = {
  isAppLoaded: false
}


const store = new Vuex.Store({
  state: initialState,
  getters,
  mutations,
  actions,
  modules: {
    user,
    devises,
    societes,
    app,
    appConfig,
    verticalMenu,
    subscription
  },
  strict: process.env.DEV,
})

global.store = store
export default store
