export default {
    bootstrap({ commit, dispatch, state }) {
        return new Promise((resolve, reject) => {
            window.axios.get('/societes').then((response) => {
                commit('society/INIT_APP', response.data.data)
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}
