export default {
  state: {
    login: false,
    auth: {},
  },

  getters: {
    isLoggedIn(state) {
      return state.login
    },
  },

  mutations: {
    LOGIN(state, status) {
      state.login = status
      state.auth = {}
    },

    AUTH_USER(state, user) {
      state.auth = user
    },
  },

  actions: {},
}
