import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Accueil',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Tableau de bord',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/devises',
      name: 'parametres/devises',
      component: () => import('@/views/parametres/Devises.vue'),
      meta: {
        pageTitle: 'Devises',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Devises',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/signataires',
      name: 'parametres/signataires',
      component: () => import('@/views/parametres/Signataires.vue'),
      meta: {
        pageTitle: 'Signataires',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Signataires',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/navires',
      name: 'parametres/navires',
      component: () => import('@/views/parametres/Navires.vue'),
      meta: {
        pageTitle: 'Navires',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Navires',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/clients',
      name: 'parametres/clients',
      component: () => import('@/views/parametres/Clients.vue'),
      meta: {
        pageTitle: 'Clients',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Clients',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/demarcheurs',
      name: 'parametres/demarcheurs',
      component: () => import('@/views/parametres/Demarcheurs.vue'),
      meta: {
        pageTitle: 'Demarcheurs',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Demarcheurs',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/caracteristiquesvehicules',
      name: 'parametres/caracteristiquesvehicules',
      component: () => import('@/views/parametres/CaracteristiquesVehicules.vue'),
      meta: {
        pageTitle: 'Caractéristiques',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Caractéristiques',
            active: false,
          },
        ],
      },
    },

    {
      path: '/manifestes',
      name: 'manifestes',
      component: () => import('@/views/manifestes/index.vue'),
      meta: {
        pageTitle: 'Manifestes',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Manifestes',
            active: false,
          },
        ],
      },
    },
    {
      path: '/vehicules',
      name: 'vehicules',
      component: () => import('@/views/vehicules/index.vue'),
      meta: {
        pageTitle: 'Vehicules',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Vehicules',
            active: false,
          },
        ],
      },
    },
    {
      path: '/vehicules/details/:id',
      name: 'vehicules-details',
      component: () => import('@/views/vehicules/details.vue'),
      meta: {
        pageTitle: 'Vehicules',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Détails du véhicule',
            active: false,
          },
        ],
      },
    },
    {
      path: '/dedomagements',
      name: 'dedomagements',
      component: () => import('@/views/dedomagements/index.vue'),
      meta: {
        pageTitle: 'Dédomagements',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Dédomagements',
            active: false,
          },
        ],
      },
    },
    {
      path: '/recouvrements',
      name: 'recouvrements',
      component: () => import('@/views/recouvrer/index.vue'),
      meta: {
        pageTitle: 'Recouvrements',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Recouvrements',
            active: false,
          },
        ],
      },
    },
    {
      path: '/manifestes/add',
      name: 'manifesteAdd',
      component: () => import('@/views/manifestes/add.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Ajouter un manifeste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/manifestes/details',
      name: 'manifesteDetails',
      component: () => import('@/views/manifestes/details.vue'),
      meta: {
        pageTitle: 'Détails manifeste',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Détails du manifeste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/manifestes/print',
      name: 'manifestes-print',
      component: () => import('@/views/manifestes/print.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/personnels/fiche-de-paie',
      name: 'personnel-print-fiche-de-paie',
      component: () => import('@/views/personnels/print/fiche-de-paie.vue'),
      meta: {
        authOnly: true,
        activateOnly: true,
        layout: 'full'
      },
    },
    {
      path: '/manifestes/print/:id',
      name: 'manifestes-print-id',
      component: () => import('@/views/manifestes/print.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/importation/manifestes',
      name: 'importation/manifestes',
      component: () => import('@/views/importation/manifeste/index.vue'),
      meta: {
        pageTitle: 'Manifestes',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Manifestes',
            active: false,
          },
        ],
      },
    },
    {
      path: '/importation/importateurs',
      name: 'importation/importateurs',
      component: () => import('@/views/importation/Importateurs.vue'),
      meta: {
        pageTitle: 'Importateurs',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Importateurs',
            active: false,
          },
        ],
      },
    },
    {
      path: '/importation/print/carte',
      name: 'importation-print-carte',
      component: () => import('@/views/importation/print/carte.vue'),
      meta: {
        pageTitle: 'Importateurs',
        authOnly: true,
        activateOnly: true,
        layout: "full"
      },
    },
    {
      path: '/manifeste/ajouter',
      name: 'manifeste-add',
      component: () => import('@/views/importation/manifeste/add.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/manifeste/details',
      name: 'manifeste-details',
      component: () => import('@/views/importation/manifeste/details.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/manifeste/importation-print',
      name: 'importation-print',
      component: () => import('@/views/importation/manifeste/print.vue'),
      meta: {
        pageTitle: '',
        layout: "full",
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/importation/vehicules',
      name: 'importation/vehicules',
      component: () => import('@/views/importation/Vehicules.vue'),
      meta: {
        pageTitle: 'Vehicules',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Vehicules',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/societes',
      name: 'parametres/societes',
      component: () => import('@/views/parametres/Societes.vue'),
      meta: {
        pageTitle: 'Société',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Paramètres',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/chargeurs',
      name: 'parametres/chargeurs',
      component: () => import('@/views/parametres/Chargeurs.vue'),
      meta: {
        pageTitle: 'Chargeurs',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Chargeurs',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/acheteurs',
      name: 'parametres/acheteurs',
      component: () => import('@/views/parametres/Acheteurs.vue'),
      meta: {
        pageTitle: 'Acheteurs',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Acheteurs',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/associes',
      name: 'parametres/associes',
      component: () => import('@/views/parametres/Associes.vue'),
      meta: {
        pageTitle: 'Associés',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Associes',
            active: false,
          },
        ],
      },
    },
    {
      path: '/parametres/partenaires',
      name: 'parametres/partenaires',
      component: () => import('@/views/parametres/Partenaires.vue'),
      meta: {
        pageTitle: 'Partenaires',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Partenaires',
            active: false,
          },
        ],
      },
    },
    {
      path: '/utilisateur/profile',
      name: 'utilisateurs-profile',
      component: () => import('@/views/users/Me.vue'),
      meta: {
        pageTitle: 'Utilisateur',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Mon compte',
            active: false,
          },
        ],
      },
    },
    {
      path: '/utilisateur/create/:id',
      name: 'utilisateurs-create',
      component: () => import('@/views/utilisateurs/utilisateur/create.vue'),
      meta: {
        pageTitle: 'Utilisateur',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Ajout utilisateur',
            active: false,
          },
        ],
      },
    },
    {
      path: '/groups/modules',
      name: 'groups-modules',
      component: () => import('@/views/utilisateurs/groupeUtilisateur/module.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/utilisateur-edit',
      name: 'utisateur-edit',
      component: () => import('@/views/users/edit.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        guestOnly: true,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/error/subscription.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/utilisateurs/utilisateurs',
      name: 'utilisateurs/utilisateurs',
      component: () => import('@/views/utilisateurs/utilisateur/index.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des utilisateurs',
            active: false,
          },
        ]
      },
    },
    {
      path: '/utilisateurs/groups',
      name: 'utilisateurs/groups',
      component: () => import('@/views/utilisateurs/groupeUtilisateur/index.vue'),
      meta: {
        pageTitle: 'Groupes utilisateurs',
        breadcrumb: [
          {
            text: 'Liste des groupes utilisateurs',
            active: false,
          },
        ]
      },
    },

    {
      path: '/personnels/membres',
      name: 'personnels-membres',
      component: () => import('@/views/personnels/membres.vue'),
      meta: {
        pageTitle: 'Membres',
        breadcrumb: [
          {
            text: 'Liste des membres',
            active: false,
          },
        ]
      },
    },

    {
      path: '/personnels/conges',
      name: 'personnels-conges',
      component: () => import('@/views/personnels/conges.vue'),
      meta: {
        pageTitle: 'Congés',
        breadcrumb: [
          {
            text: 'Liste des congés',
            active: false,
          },
        ]
      },
    },

    {
      path: '/personnels/salaires',
      name: 'personnels-salaires',
      component: () => import('@/views/personnels/salaires.vue'),
      meta: {
        pageTitle: 'Salaires',
        breadcrumb: [
          {
            text: 'Liste des Salaires',
            active: false,
          },
        ]
      },
    },

    {
      path: '/personnels/print/fiche-pret',
      name: 'personnel-print-fiche-de-pret',
      component: () => import('@/views/personnels/print/fiche-de-pret.vue'),
      meta: {
        pageTitle: 'Fiche de prêt',
        layout: 'full',
      },
    },

    {
      path: '/personnels/print/permission-conge',
      name: 'personnel-print-permission-conge',
      component: () => import('@/views/personnels/print/fiche-de-conge.vue'),
      meta: {
        pageTitle: 'Note de congé',
        layout: 'full',
      },
    },

    {
      path: '/personnels/print/fiche-remboursement',
      name: 'personnel-print-fiche-de-remboursement',
      component: () => import('@/views/personnels/print/fiche-de-remboursement.vue'),
      meta: {
        pageTitle: 'Fiche de remboursement',
        layout: 'full',
      },
    },

    {
      path: '/associations',
      name: 'associations',
      component: () => import('@/views/associations/index.vue'),
      meta: {
        pageTitle: 'Associations',
        breadcrumb: [
          {
            text: 'Liste des associations',
            active: false,
          },
        ]
      },
    },
    {
      path: '/associations/show/:id',
      name: 'associations-show',
      component: () => import('@/views/associations/detail.vue'),
      meta: {
        pageTitle: 'Associations',
        breadcrumb: [
          {
            text: "Détail de l'association ",
            active: false,
          },
        ]
      },
    },
    {
      path: '/associations/resume/:id',
      name: 'associations-resume',
      component: () => import('@/views/associations/resume.vue'),
      meta: {
        pageTitle: 'Associations',
        breadcrumb: [
          {
            text: 'Poursuivre enregistrement',
            active: false,
          },
        ]
      },
    },
    {
      path: '/associations/create',
      name: 'associations-create',
      component: () => import('@/views/associations/create.vue'),
      meta: {
        pageTitle: 'Association',
        breadcrumb: [
          {
            text: 'Ajout association',
            active: false,
          },
        ],
      },
    },

    {
      path: '/facture/proforma/:id',
      name: 'proforma-show',
      component: () => import('@/views/factures/proforma/show.vue'),
      meta: {
        pageTitle: 'Facture proforma',
        layout: 'full',
      },
    },

    {
      path: '/facture/proforma',
      name: 'facture/proforma',
      component: () => import('@/views/factures/proforma/index.vue'),
      meta: {
        pageTitle: 'Facture proforma',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des factures proforma',
            active: false,
          },
        ],
      },
    },

    {
      path: '/factures',
      name: 'factures',
      component: () => import('@/views/factures/facture/index.vue'),
      meta: {
        pageTitle: 'Factures',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des factures',
            active: false,
          },
        ],
      },
    },

    {
      path: '/facture/:id',
      name: 'facture-show',
      component: () => import('@/views/factures/facture/show.vue'),
      meta: {
        pageTitle: 'Facture',
        layout: 'full',
      },
    },

    {
      path: '/banque',
      name: 'banque',
      component: () => import('@/views/finance/banque/index.vue'),
      meta: {
        pageTitle: 'Banque',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des comptes',
            active: false,
          },
        ],
      },
    },

    {
      path: '/banque/virement',
      name: 'banque-virement',
      component: () => import('@/views/finance/banque/virement.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },

    {
      path: '/banque/virement/details',
      name: 'banque-virement-details',
      component: () => import('@/views/finance/banque/virementDetails.vue'),
      meta: {
        pageTitle: '',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: '',
            active: false,
          },
        ],
      },
    },
    {
      path: '/operation/print',
      name: 'operation-print',
      component: () => import('@/views/finance/banque/print.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/compte/print',
      name: 'compte-print',
      component: () => import('@/views/finance/comptes/print.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/finances/journal-caisse',
      name: 'journal-caisse',
      component: () => import('@/views/finance/journalCaisse/index.vue'),
      meta: {
        pageTitle: 'Journal de caisse',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Journal de caisse',
            active: false,
          },
        ],
      },
    },
    {
      path: '/finances/comptes',
      name: 'comptes',
      component: () => import('@/views/finance/comptes/index.vue'),
      meta: {
        pageTitle: 'Comptes',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des comptes',
            active: false,
          },
        ],
      },
    },
    {
      path: '/finances/comptes/details',
      name: 'compte-details',
      component: () => import('@/views/finance/comptes/details.vue'),
      meta: {
        pageTitle: 'Détails compte',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Détails du compte',
            active: false,
          },
        ],
      },
    },
    {
      path: '/finances/comptes/bordereau',
      name: 'finances-bordereau',
      component: () => import('@/views/finance/printable/bordereau.vue'),
      meta: {
        layout: 'full',
        authOnly: true,
        activateOnly: true,
      },
    },
    {
      path: '/finances/pretsRecouvrement',
      name: 'finances-pretsRecouvrement',
      component: () => import('@/views/finance/pretsRecouvrement.vue'),
      meta: {
        pageTitle: 'Prêts / recouvrement',
        breadcrumb: [
          {
            text: 'Liste des Prêts-Recouvrement',
            active: false,
          },
        ]
      },
    },
    {
      path: '/archives/vehicules',
      name: 'archives/vehicules',
      component: () => import('@/views/archives/Vehicules.vue'),
      meta: {
        pageTitle: 'Vehicules',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Vehicules',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/factures',
      name: 'archives/factures',
      component: () => import('@/views/archives/Factures.vue'),
      meta: {
        pageTitle: 'Factures',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Factures',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/proforma',
      name: 'archives/proforma',
      component: () => import('@/views/archives/ProFactures.vue'),
      meta: {
        pageTitle: 'Proforma',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Factures Proforma',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/manifestes',
      name: 'archives/manifestes',
      component: () => import('@/views/archives/Manifestes.vue'),
      meta: {
        pageTitle: 'Manifestes',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des Manifestes',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/comptes',
      name: 'archives/comptes',
      component: () => import('@/views/archives/Comptes.vue'),
      meta: {
        pageTitle: 'Comptes archivés',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des comptes',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/dedomagements',
      name: 'archives/dedomagements',
      component: () => import('@/views/archives/Dedomagements.vue'),
      meta: {
        pageTitle: 'Dedomagements',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des dédomagements',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/comptes/details/:id',
      name: 'archives/compte-details',
      component: () => import('@/views/archives/JournalCompte.vue'),
      meta: {
        pageTitle: 'Détails compte',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Détails du compte',
            active: false,
          },
        ],
      },
    },
    {
      path: '/archives/caisse',
      name: 'archives/caisse',
      component: () => import('@/views/archives/JournalCaisse.vue'),
      meta: {
        pageTitle: 'Journal de caisse archivé',
        authOnly: true,
        activateOnly: true,
        breadcrumb: [
          {
            text: 'Liste des opérations',
            active: false,
          },
        ],
      },
    },
    {
      path: '/inventaire/:id',
      name: 'inventaire-show',
      component: () => import('@/views/inventaire/show.vue'),
      meta: {
        pageTitle: 'Inventaire',
        breadcrumb: [
          {
            text: 'Détail Inventaire',
            active: false,
          },
        ],
      },
    },
    {
      path: '/print-inventaire/:id',
      name: 'inventaire-print',
      component: () => import('@/views/inventaire/print.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/inventaire/nouveau/:id',
      name: 'inventaire-nouveau',
      component: () => import('@/views/inventaire/create.vue'),
      meta: {
        pageTitle: 'Inventaire',
        breadcrumb: [
          {
            text: 'Nouveau Inventaire',
            active: false,
          },
        ],
      },
    },

    {
      path: '/inventaire',
      name: 'inventaire',
      component: () => import('@/views/inventaire/index.vue'),
      meta: {
        pageTitle: 'Inventaire',
        breadcrumb: [
          {
            text: 'Inventaire',
            active: false,
          },
        ],
      },
    },

  ],
})

function isLoggedIn() {
  return localStorage.getItem('flash_token')
}

function isActivated() {
  return localStorage.getItem('coco_act')
}

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      if (!localStorage.getItem('coco_act') || localStorage.getItem('coco_act') == "0") {
        // next()
        next({
          path: '/error-403',
          // query: { redirect: to.fullPath },
        })
      }
      else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (isLoggedIn()) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
