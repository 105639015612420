export default {
  install(Vue, options) {
    // create a mixin 
    Vue.mixin({
      created() {
      }
    });

    Vue.VERSION = 'v2.0.3';

    Vue.prototype.$thousandFormater = function (amount) {
      if (amount !== '' &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== '0' &&
        amount !== null
      ) {
        let montant = amount.toString().replace(/,/g, "")
        montant = montant.toString().replace(/ /g, "")

        return parseInt(montant)
      } else {
        return 0
      }

    }
  }
}