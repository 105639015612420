export default {
    state: {
      isSubscribed: null,
      subscriptionData: {}
    },
  
    getters: {
      getSubscription(state) {
        return state.isSubscribed
      },
      getSubscriptionData(state) {
        return state.subscriptionData
      }
    },
  
    mutations: {
      setSubscription(state, subscription) {
        state.isSubscribed = subscription
      },
      setSubscriptionData(state, data) {
        state.subscriptionData = data
      }
    },
  
    actions: {
      verifySubscription({ commit }) {

        let host = window.location.host;

        return this._vm.$http.get(`/abonnement/${host}`).then(result => {
          commit('setSubscription', result.data.success)
          commit('setSubscriptionData', result.data.data)
        })
      }
    }
  }
  