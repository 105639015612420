export default {
    install(Vue, options) {
        // create a mixin 
        Vue.mixin({
            created() {
            }
        });

        Vue.VERSION = 'v2.0.3';

        Vue.prototype.$formatDate = function (date) {
            // if (date) {
            //     date = date.replace(/\//g, '-')
            //     var parts = date.split('-')
            //     return parts[2] + '-' + parts[1] + '-' + parts[0]
            // } else {
            //     return "";
            // }
            if (date) {
                date = new Date(date);
                let annee = date.getFullYear()
                let mois = ('0' + (date.getMonth() + 1)).slice(-2)
                let jour = ('0' + date.getDate()).slice(-2)
                date = jour + '-' + mois + '-' + annee
                return date
            }
            return date
        }
    }
}