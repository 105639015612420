export default {
  state: {
    items: [],
    devManifeste: {},
  },

  getters: {
    // isLoggedIn(state) {
    //     return state.login
    // },
  },

  mutations: {
    allDevises(state, items) {
      state.items = items
    },

    addDevise(state, item) {
      state.items.push(item)
    },

    editDevise(state, item) {
      const last = state.items.find(({ id }) => id === item.id)
      Object.assign(last, item)
    },

    removeDevise(state, item) {
      const index = state.items.indexOf(item)
      state.items.splice(index, 1)
    },

    getManifesteDevises(state, item) {
      if (item[0]) {
        state.devManifeste = item[0]
      }
    },

    setManifesteDevises(state, item) {
      state.devManifeste = item
    },
  },

  actions: {
    fetchDevises({ commit }) {
      return this._vm.$http.get('/devises').then(result => {
        commit('allDevises', result.data.data)
      })
    },
    fetchDevisesManifeste({ commit }) {
      return this._vm.$http.get('/devises-manifeste').then(result => {
        commit('getManifesteDevises', result.data.data)
      })
    },
  },
}
