import Vue from 'vue'
const defaultOptions = {
   cutoff: 50
 };
export default {
   // called by Vue.use(FirstPlugin)
   install(Vue, options) {

      // merge default options with arg options
      let userOptions = {...defaultOptions, ...options};

       // create a mixin 
      Vue.mixin({
         created() {
         }
      });

      // define a global property
    Vue.VERSION = 'v2.0.3';

      Vue.prototype.$hasFonction = function (user,fonction) {
         let array = user.fonctions;
         // console.log('hasFonction')
         // console.log(user)
         let count = 0;
         if(array){
            for (var i = 0; i < array.length; i++) {
               var name = 'Mise a jour privilège'
               // console.log('**************************')
               // console.log(array[i].libelle.toLowerCase())
               if(array[i].libelle.toLowerCase() == name.toLowerCase()){
                  // console.log('non Mise$$$$$$$$$$$$$$$$$$$$$$$')
                  // console.log(array[i].libelle.toLowerCase())

               }
               if ((array[i].show == 1) && (fonction.toLowerCase() == array[i].libelle.toLowerCase())) {
               count++;
               }
            }
         }
         
         if(count) return true;
         return false;
      }
   }

}

