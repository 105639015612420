export default {
  state: {
    society: {},
  },

  getters: {
    getSociety(state) {
      return state.society
    },
  },

  mutations: {
    setSocietyData(state, society) {
      state.society = society
    },
  },

  actions: {
    fetchSocieties({ commit }) {
      return this._vm.$http.get('/societes').then(result => {
        console.log(result)
        commit('setSocietyData', result.data.data)
      })
    },
  }
  //   export const fetchCustomers = ({ commit, dispatch, state }, params) => {
  //     return new Promise((resolve, reject) => {
  //       window.axios.get(`/api/customers`, {params}).then((response) => {
  //         commit(types.BOOTSTRAP_CUSTOMERS, response.data.customers.data)
  //         commit(types.SET_TOTAL_CUSTOMERS, response.data.customers.total)
  //         resolve(response)
  //       }).catch((err) => {
  //         reject(err)
  //       })
  //     })
  //   }
  // },
}
